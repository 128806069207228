<script>
	import { getContext, setContext } from 'svelte';

	let {
		columns = 1,
		rows = $bindable(1),
		as: htmlTag = $bindable('div'),
		center = false,
		subgrid = false,
		vertical = false,
		class: className = '',
		children,
	} = $props();

	if (subgrid) {
		rows = getContext('rows');
		htmlTag = htmlTag ?? 'li';
	} else {
		htmlTag = htmlTag ?? 'ul';
		setContext('rows', rows);
	}
</script>

<!-- these subgrid rules are adding unexpected spacing in firefox, removing for now
	class:[@supports(grid-template-rows:subgrid)]:grid={subgrid}
	class:[@supports(grid-template-rows:subgrid)]:grid-rows-[subgrid]={subgrid}
	class:[@supports(grid-template-rows:subgrid)]:row-[span_var(--rows)]={subgrid} -->
<svelte:element
	this={htmlTag}
	style={`
		--columns: ${columns};
		--rows: ${rows};
	`}
	class:md:grid-rows-[repeat(var(--rows),_min-content)]={rows}
	class:md:grid-cols-[repeat(var(--columns),1fr)]={columns}
	class:grid-flow-row={vertical}
	class:md:grid-flow-col={!vertical}
	class:justify-items-center={center}
	class:md:contents={subgrid}
	class:max-md:gap-y-8={!subgrid}
	class={[
		'mb-4 grid w-full grid-flow-row gap-x-8 gap-y-2',
		className ?? '',
	].join(' ')}
>
	{@render children?.()}
</svelte:element>
